import React, { useMemo, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const getMinSprintNumberFromURL = location =>
  Number(new URLSearchParams(location.search).get("minSprint"));
const getMaxSprintNumberFromURL = location =>
  Number(new URLSearchParams(location.search).get("maxSprint"));

const getCustomFieldNameFromURL = location =>
  new URLSearchParams(location.search).get("customField");

const GranularFilters = ({ history, sprints }) => {
  const { location } = history;

  const setURLParam = useCallback(
    (key, value) => {
      const { pathname, search } = location;

      const urlSearchParams = new URLSearchParams(search);

      urlSearchParams.set(key, value);

      history.push(`${pathname}?${urlSearchParams.toString()}`);
    },
    [location, history]
  );

  const minSprint = useMemo(
    () => (
      sprints.firstWhere("startOnUnix", getMinSprintNumberFromURL(location)) ||
        sprints.where("isCompletedSprint").take(2).last()
    ),
    [location, sprints]
  );
  const setMinSprint = useCallback(
    ({ startOnUnix }) => setURLParam("minSprint", startOnUnix),
    [setURLParam]
  );

  const maxSprint = useMemo(
    () => (
      sprints.firstWhere("startOnUnix", getMaxSprintNumberFromURL(location)) ||
        sprints.where("isForecastSprint").sortBy("startOnUnix").take(2).last()
    ),
    [location, sprints]
  );
  const setMaxSprint = useCallback(
    ({ startOnUnix }) => setURLParam("maxSprint", startOnUnix),
    [setURLParam]
  );

  const customFields = useMemo(
    () =>
      sprints
        .pluck("customFieldNames")
        .flatten(1)
        .unique()
        .sortBy(),
    [sprints]
  );

  const customFieldName = useMemo(() => getCustomFieldNameFromURL(location), [
    location
  ]);
  const setCustomField = useCallback(name => setURLParam("customField", name), [
    setURLParam
  ]);

  useEffect(() => {
    if (!customFieldName && customFields.isNotEmpty()) {
      setCustomField(customFields.first());
    }
  }, [customFieldName, customFields, setCustomField]);

  useEffect(() => {
    if (minSprint?.startOnUnix && getMinSprintNumberFromURL(location) !== minSprint.startOnUnix) {
      setMinSprint({ startOnUnix: minSprint.startOnUnix });
    }
  }, [location, setMinSprint, minSprint?.startOnUnix]);

  useEffect(() => {
    if (maxSprint?.startOnUnix && getMaxSprintNumberFromURL(location) !== maxSprint.startOnUnix) {
      setMaxSprint({ startOnUnix: maxSprint.startOnUnix });
    }
  }, [location, setMaxSprint, maxSprint?.startOnUnix]);

  return (
    <>
      <span>Showing reports from </span>
      <Dropdown className="text-dark pr-2 text-capitalize d-inline">
        <Dropdown.Toggle>
          <span>Sprint </span>
          <span>{minSprint?.number}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {sprints
            .when(maxSprint?.startOnUnix, collection =>
              collection.where("startOnUnix", "<", maxSprint.startOnUnix)
            )
            .map(obj => (
              <Dropdown.Item
                key={obj.startOnUnix}
                onSelect={() => setMinSprint(obj)}
                disabled={obj.startOnUnix === minSprint?.startOnUnix}
              >
                Sprint {obj.number}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <span>to </span>
      <Dropdown className="text-dark pr-2 text-capitalize d-inline">
        <Dropdown.Toggle>
          <span>Sprint </span>
          <span>{maxSprint?.number}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {sprints
            .when(minSprint?.startOnUnix, collection =>
              collection.where("startOnUnix", ">", minSprint.startOnUnix)
            )
            .map(obj => (
              <Dropdown.Item
                key={obj.startOnUnix}
                onSelect={() => setMaxSprint(obj)}
                disabled={obj.startOnUnix === maxSprint?.startOnUnix}
              >
                Sprint {obj.number}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <span>based on </span>
      <Dropdown className="text-dark pr-2 text-capitalize d-inline">
        <Dropdown.Toggle variant="primary">{customFieldName}</Dropdown.Toggle>
        <Dropdown.Menu>
          {customFields.map(obj => (
            <Dropdown.Item
              key={obj}
              onSelect={() => setCustomField(obj)}
              disabled={obj === customFieldName}
            >
              {obj}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const withSprintFiltersFromURL = WrappedComponent =>
  withRouter(props => {
    const { location } = props;

    const minSprintNumberFromURL = useMemo(
      () => getMinSprintNumberFromURL(location),
      [location]
    );
    const maxSprintNumberFromURL = useMemo(
      () => getMaxSprintNumberFromURL(location),
      [location]
    );

    const customFieldNameFromUrl = useMemo(
      () => getCustomFieldNameFromURL(location),
      [location]
    );

    return (
      <WrappedComponent
        {...props}
        minSprintNumber={minSprintNumberFromURL}
        maxSprintNumber={maxSprintNumberFromURL}
        customFieldName={customFieldNameFromUrl}
      />
    );
  });

export default withRouter(GranularFilters);
